import styled from '@emotion/styled';
import clsx from 'clsx';

import { breakpoints, screen } from '@/components/common/breakpoints';
import { Button } from '@/components/common/Buttons';
import { Section } from '@/components/layout/Section';
import { useMediaQuery } from '@/lib/useMediaQuery';
import { blockUrlWithAudience } from '@/lib/utils';

import type { SanityCtaButtonType } from '@/types/sanity';
import type { BlockWithAudience, CommonCTAProps } from '@/types/shared';

export interface CtaButtonProps
  extends Omit<SanityCtaButtonType, 'CTA' | '_type'>,
    BlockWithAudience {
  CTA: CommonCTAProps;
  _type?: 'ctaButton';
  className?: string;
}

export const CtaButton = ({
  variant = 'solid-light',
  alignment = 'center',
  CTA,
  blockSpacing,
  paddingAdjustment,
  pageAudience,
  isAudienceSwitcherEnabled,
  className,
}: CtaButtonProps) => {
  const isLargerThanMobile = useMediaQuery(screen.smQuery);

  return (
    <Section
      className={clsx(className, 'cta-button-block')}
      verticalPadding={paddingAdjustment ?? true}
      spacing={blockSpacing}
    >
      <Container alignment={alignment}>
        <StyledButton
          size="lg"
          variant={variant}
          href={blockUrlWithAudience(
            CTA.url,
            CTA.audienceRef,
            pageAudience,
            CTA.noAudienceToggle,
            isAudienceSwitcherEnabled,
          )}
          aria-label={CTA?.accessibleLabel}
          className={
            CTA.trackingTag
              ? `tracking-${CTA.trackingTag.blockName}__${CTA.trackingTag.blockAction}`
              : ''
          }
          iconPosition="right"
          icon={isLargerThanMobile ? 'ArrowRight' : null}
        >
          {CTA.text}
        </StyledButton>
      </Container>
    </Section>
  );
};

const Container = styled.div<{ alignment: CtaButtonProps['alignment'] }>`
  display: flex;
  justify-content: ${({ alignment }) => alignment};
`;

const StyledButton = styled(Button)`
  @media (max-width: ${breakpoints.sm}) {
    width: 100%;
    justify-content: center;
  }
`;
